import axios from 'axios';
import { AUTH_USER, AUTH_ERROR } from './types';

const host = process.env.REACT_APP_GRAPHQL_HOST;

console.log(host);

export const signup = (formProps, callback) => async dispatch => {
  try {
    const response = await axios.post(
      `https://${host}:4001/signup`,
      formProps
    );

    dispatch({ type: AUTH_USER, payload: response.data.token });
    localStorage.setItem('token', response.data.token);
    callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: 'Email in use' });
  }
};

export const signin = (formProps, callback) => async dispatch => {
  console.log("formProps:", formProps);
  try {
    const response = await axios.post(
      `https://${host}:4002/signin`,
      formProps
    );

    dispatch({ type: AUTH_USER, payload: response.data.token });
    localStorage.setItem('token', response.data.token);
    callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: 'Invalid login credentials' });
  }
};

export const signout = () => {
  console.log("signout called");
  localStorage.removeItem('token');

  return {
    type: AUTH_USER,
    payload: ''
  };
};
