import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import reducers from './reducers';

const store = createStore(
  reducers,
  {
    auth: { authenticated: localStorage.getItem('token')}
  },
  applyMiddleware(reduxThunk)
);

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  }
});

const Main = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>

  </ApolloProvider>
);

ReactDOM.render(<Main />, document.getElementById("root"));
registerServiceWorker();
