import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TableSortLabel,
  Typography,
  TextField
} from "@material-ui/core";

const styles = theme => ({
  root: {
    maxWidth: 700,
    marginTop: theme.spacing(3),
    overflowX: "auto",
    margin: "auto"
  },
  table: {
    minWidth: 700
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 300
  },
  fieldset: {
    borderColor: "#ffffff",
    borderStyle: "solid"
  },
  form: {
    margin: 30,
    padding: 25,
    border: "1px solid grey",
    textAlign: "center"
  },
  label: {
    display: "block"
  },
  field: {
    border: "none",
    borderBottom: "1px solid grey",
    height: "2em",
    fontSize: "120%",
    color: "red"
  }
});

class Signin extends Component {
  onSubmit = formProps => {
    this.props.signin(formProps, () => {
      this.props.history.push('/artikel');
    });
  };

  render() {
    const { handleSubmit, classes } = this.props;

    return (
      <Paper
        style={{minHeight:'600px'}}
        className={classes.root}>
        <Typography
          style={{marginTop:'15px',marginLeft:'15px'}}
          variant="h4" gutterBottom>
          Login
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(this.onSubmit)}>
          <fieldset className={classes.fieldset}>
            <label className={classes.label}>Username</label>
            <Field
              className={classes.field}
              name="username"
              type="text"
              component="input"
              autoComplete="none"
            />
          </fieldset>
          <fieldset className={classes.fieldset}>
            <label className={classes.label}>Passwort</label>
            <Field
              className={classes.field}
              name="password"
              type="password"
              component="input"
              autoComplete="none"
            />
          </fieldset>
          <div style={{color:"orange"}}>{this.props.errorMessage}</div>
          <Button type="submit">Einloggen</Button>
        </form>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.errorMessage };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, actions),
  reduxForm({ form: 'signin' })
)(Signin);
