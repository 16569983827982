import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import "./UserList.css";
import { withStyles } from "@material-ui/core/styles";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TableSortLabel,
  TableLabel,
  Typography,
  TextField
} from "@material-ui/core";

// Kürzel für die Prüfbücher
const pb_options = [

  {value: 'ajs', label: 'AJS'},
  {value: 'atd', label: 'ATD'},
  {value: 'ctd_iii', label: 'CTD III'},
  {value: 'gurte', label: 'Gurte'},
  {value: 'kat', label: 'KAT'},
  {value: 'kim', label: 'KIM'},
  {value: 'netzsysteme', label: 'Netzsysteme'},
  {value: 'pat', label: 'PAT'},
  {value: 'sam', label: 'SAM'},
  {value: 'trs', label: 'TRS'},
  {value: 'tsg', label: 'TransSAFE GO'},
  {value: 'tsr', label: 'TransSAFE Rack'},
  {value: null, label: '-'}
]

const styles = theme => ({
  root: {
    maxWidth: 700,
    marginTop: theme.spacing(3),
    overflowX: "auto",
    margin: "auto"
  },
  table: {
    minWidth: 700
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 300
  }
});

const GET_ARTIKEL = gql`
  query artikelsPaginateQuery(
    $first: Int
    $offset: Int
    $orderBy: [_ArtikelOrdering]
    $filter: _ArtikelFilter
  ) {
    Artikel(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      id
      name
      ynum
      pruefbuch {
        name
        akronym
      }
    }
    pruefbuchRelationGeneration
  }
`;

const CREATE_ARTIKEL = gql`
  mutation createArtikelMutation(
    $ynum: String
    $name: String
  ) {
    CreateArtikel(ynum: $ynum, name: $name) {
      ynum
      name
    }
  }
`;

const DELETE_ARTIKEL = gql`
  mutation deleteArtikelMutation(
    $id: ID!
  ) {
    DeleteArtikel(id: $id) {
      id
    }
  }
`;

const SET_ARTIKEL_PRUEFBUCH = gql`
  mutation setArtikelPruefbuch(
    $akronym: String, $ynum: String, $generation: Int
  ) {
    setArtikelPruefbuch(akronym: $akronym, ynum: $ynum, generation: $generation) {
      id
      ynum
    }
  }
`;

const REMOVE_ARTIKEL_PRUEFBUCH = gql`
  mutation removeArtikelPruefbuch(
    $ynum: String
  ) {
    removeArtikelPruefbuch(ynum: $ynum) {
      id
      ynum
    }
  }
`;


function ArtikelList(props) {
  const { classes } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("ynum");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filterState, setFilterState] = React.useState({ artikelynumFilter: "" });
  const [artikelToDelete, setArtikelToDelete] = React.useState("")
  const [newArtikelName, setNewArtikelName] = React.useState("")

  //console.log(setPage);
  //console.log(setRowsPerPage);

  const getFilter = () => {
    return filterState.artikelynumFilter.length > 0
      ? { ynum_contains: filterState.artikelynumFilter }
      : {};
  };

  const [createArtikel] = useMutation(
    CREATE_ARTIKEL,
    {
      refetchQueries: [
        { query: GET_ARTIKEL,
          variables: {
            first: rowsPerPage,
            offset: rowsPerPage * page,
            orderBy: orderBy + "_" + order,
            filter: getFilter()
          }
        }
      ],
    }
  );

/*


*/

  const [deleteArtikel] = useMutation(
    DELETE_ARTIKEL,
    {
        refetchQueries: [
          { query: GET_ARTIKEL,
            variables: {
              first: rowsPerPage,
              offset: rowsPerPage * page,
              orderBy: orderBy + "_" + order,
              filter: getFilter()
            }
          }
        ],
      }
    );

  const [setArtikelPruefbuch] = useMutation(
    SET_ARTIKEL_PRUEFBUCH,
    {
        refetchQueries: [
          { query: GET_ARTIKEL,
            variables: {
              first: rowsPerPage,
              offset: rowsPerPage * page,
              orderBy: orderBy + "_" + order,
              filter: getFilter()
            }
          }
        ],
      }
  )

  const [removeArtikelPruefbuch] = useMutation(
    REMOVE_ARTIKEL_PRUEFBUCH,
    {
        refetchQueries: [
          { query: GET_ARTIKEL,
            variables: {
              first: rowsPerPage,
              offset: rowsPerPage * page,
              orderBy: orderBy + "_" + order,
              filter: getFilter()
            }
          }
        ],
      }
  )

  const { loading, data, error } = useQuery(GET_ARTIKEL, {
    variables: {
      first: rowsPerPage,
      offset: rowsPerPage * page,
      orderBy: orderBy + "_" + order,
      filter: getFilter()
    }
  });

  //const { }

  const handleSortRequest = property => {
    const newOrderBy = property;
    let newOrder = "desc";

    if (orderBy === property && order === "desc") {
      newOrder = "asc";
    }

    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  const handleFilterChange = filterName => event => {
    //console.log(filterState);
    const val = event.target.value;

    setFilterState(oldFilterState => ({
      ...oldFilterState,
      [filterName]: val
    }));
  };

  const handleNameChange = name_string => {
    setNewArtikelName(name_string);
  }

  const handleDeleteRequest = id_string => {
    const id = id_string;
    setArtikelToDelete(id);
    if (id !== '') {
      console.log(`trying to delete ${id_string}`);
      deleteArtikel({variables: { id }});
    } else {
      console.log("id ist leer");
    }

  }

  const handleSelectArtikelPruefbuch = (artikel_ynum, pruefbuch_akronym, generation) => {
    if (pruefbuch_akronym === null) {
      console.log("Pruefbuch loeschen!")
      removeArtikelPruefbuch({variables: { ynum: artikel_ynum }})
    } else {
      console.log('creating artikel_pruefbuch_relationship:', artikel_ynum, pruefbuch_akronym);
      setArtikelPruefbuch({variables: { akronym: pruefbuch_akronym, ynum: artikel_ynum, generation }})
    }
  }

  return (
    <Paper
      style={{minHeight:'600px'}}
      className={classes.root}>
      <Typography
        style={{marginTop:'15px',marginLeft:'15px'}}
        variant="h4" gutterBottom>
        Zuordnung Artikel -> Prüfbuch
      </Typography>
      <TextField
        id="search"
        label="Artikelnummer enthält"
        className={classes.textField}
        value={filterState.artikelynumFilter}
        onChange={handleFilterChange("artikelynumFilter")}
        margin="normal"
        variant="outlined"
        type="text"
        InputProps={{
          className: classes.input
        }}
      />
    {data && !loading && !error && (data.Artikel.length < 1) && (

      <React.Fragment>
          <TextField
            id="name"
            label="Name des neuen Artikels"
            className={classes.textField}
            value={newArtikelName}
            onChange={(e) => {
              setNewArtikelName(e.currentTarget.value);
              console.log(e.currentTarget.value);
            }}
            margin="normal"
            variant="outlined"
            type="text"
            InputProps={{
              className: classes.input
            }}
          />
          <Button
            style={{marginTop:'10px',marginRight:'20px',color:'green',float:'right'}}
            className={classes.button}
            onClick={() => createArtikel({ variables: { ynum: filterState.artikelynumFilter, name: newArtikelName } })}
            >Artikel neu anlegen</Button>
      </React.Fragment>

      )}

      {loading && !error && <p>Loading...</p>}
      {error && !loading && <p>Error</p>}
      {data && !loading && !error && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                key="name"
                sortDirection={orderBy === "name" ? order : false}
              >
                <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={order}
                    onClick={() => handleSortRequest("name")}
                  >
                    Name
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                key="ynum"
                sortDirection={orderBy === "ynum" ? order : false}
              >
                <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === "ynum"}
                    direction={order}
                    onClick={() => handleSortRequest("ynum")}
                  >
                    Artikelnummer
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell
                key="pruefbuch"
                sortDirection={orderBy === "pruefbuch" ? order : false}
              >
                <TableCell style={{borderBottom: 'none', textAlign: 'left'}}>
                  Prüfbuch
                </TableCell>
              </TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.Artikel.map(n => {
              //console.log(n)
              const defaultOption = n.pruefbuch ? n.pruefbuch.akronym : '-'
              return (
                <TableRow key={n.id}>
                  <TableCell component="th" scope="row">
                    {n.name}
                  </TableCell>
                  <TableCell>
                    {n.ynum}
                  </TableCell>
                  <TableCell>
                    <Dropdown options={pb_options} onChange={(e)=> handleSelectArtikelPruefbuch(n.ynum, e.value, data.pruefbuchRelationGeneration)} value={defaultOption} placeholder="Prüfbuch wählen" />
                  </TableCell>
                  <TableCell>
                    <button
                      style={{backgroundColor: '#FA6'}}
                      onClick={() => handleDeleteRequest(n.id)}
                      >Artikel löschen</button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
}

export default withStyles(styles)(ArtikelList);
