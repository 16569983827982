import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';
import { withStyles } from "@material-ui/core/styles";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  TableSortLabel,
  Typography,
  TextField
} from "@material-ui/core";

const styles = theme => ({
  root: {
    maxWidth: 700,
    marginTop: theme.spacing(3),
    overflowX: "auto",
    margin: "auto"
  }
});

class Signout extends Component {
  componentDidMount() {
    this.props.signout();
  }

  render() {
		const { classes } = this.props;
    return (
			<Paper
				style={{minHeight:'600px'}}
				className={classes.root}>

				<div>Abgemeldet</div>
			</Paper>
		)
  }
}

export default compose(
  withStyles(styles),
  connect(null, actions)
)(Signout);
